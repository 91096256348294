@import "./style/scss/GlobalsVariables.scss";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
html {
    font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
}


/* --------------------------- TEXTS  --------------------------- */

h1 {
    margin-top: 0;
    margin-bottom: 20px;
}

h2 {
    margin-top: 0;
    margin-bottom: 10px;
}

p {
    line-height: 1.8em;
    letter-spacing: 0.05em;
    font-weight: 300;
}

span {
    font-size: 15px;
}

li {
    a {
        font-size: 13px !important;
        font-weight: 400;
    }
}

h1,
h4 {
    @include media(mdDown) {
        line-height: 1.5em !important;
    }
}

h2,
h3,
h5,
h6,
p,
span {
    @include media(mdDown) {
        line-height: 1.8em !important;
    }
}


/* --------------------------- BUTTONS  --------------------------- */

.btn {
    box-shadow: none;
    &:hover {
        box-shadow: none;
        background-color: lighten($primary-color, 7%);
    }
    &:focus {
        background-color: $primary-color;
    }
}

.o-btn {
    font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
    font-weight: 500;
    letter-spacing: 0.05em;
    height: 50px;
    border-radius: $border-radius-btn;
    background-color: $primary-color;
    &.o-btn-square {
        border-radius: 0px;
    }
    &.o-btn-outlined {
        background-color: white;
        color: $primary-color;
        border: 1px solid $primary-color;
        &.focus {
            background-color: white;
        }
        &:hover {
            background-color: lighten($primary-color, 45%);
        }
    }
}

.o-btn-full-width {
    font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
    font-weight: 600;
    height: 50px;
    width: 100%;
    border-radius: $border-radius-btn;
    background-color: $primary-color;
    margin-top: 20px;
}


/* --------------------------- INPUTS  --------------------------- */

input,
textarea {
    border: 1px solid !important;
    border-radius: $border-radius-input !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-sizing: border-box !important;
    border-color: $borders-gray-color !important;
    margin-bottom: 0px !important;
    font-weight: 300;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
    // Focused input style
    &:focus:not([readonly]) {
        border: 1px solid $primary-color !important;
        box-shadow: none;
    }
}

textarea.materialize-textarea {
    min-height: 6rem;
}

.input-field {
    margin-top: 2.5rem;
    .character-counter {
        float: right;
        font-size: 12px;
        min-width: 10px;
    }
}

.input-field>label {
    color: $black-color;
    transform: translateY(12px) !important;
    top: -5px;
}

.input-field.col {
    margin-bottom: 0px !important;
}

.input-field.col label {
    font-size: 0.8em;
    font-weight: 500;
    left: 0px;
    top: -35px;
    span.required {
        position: absolute;
        top: 5px;
        right: -10px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #ba2726;
        display: block;
    }
}

.react-tel-input {
    margin-top: 2.5rem;
    .special-label {
        display: block !important;
        color: $black-color;
        font-size: 0.8em !important;
        font-weight: 500;
        left: 0px !important;
        top: -24px !important;
        font-family: "Poppins", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
        span.required {
            position: absolute;
            top: 5px;
            right: -10px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #ba2726;
            display: block;
        }
    }
    input {
        padding-left: 50px !important;
        &:focus~.flag-dropdown {
            border-top-color: $primary-color;
            border-left-color: $primary-color;
            border-bottom-color: $primary-color;
        }
    }
}


/* --------------------------- SWITCH  --------------------------- */

.switch {
    label {
        .lever::before {
            top: 1px !important;
        }
        .lever::after {
            top: 1px !important;
        }
        .lever {
            margin-right: 0px;
        }
    }
}


/* --------------------------- LETTER COLOR  --------------------------- */

html {
    color: $black-color;
}

.o-red-color {
    color: $warning-toast-color;
}

.o-primary-color {
    color: $primary-color;
}


/* --------------------------- TABS  --------------------------- */

.tabs .tab {
    text-transform: none;
    a {
        width: fit-content;
        margin: 0px auto;
        font-size: 1em !important;
        font-weight: 300;
    }
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
    background-color: transparent;
}


/* --------------------------- MODIFIERS  --------------------------- */

.fw-500 {
    font-weight: 500;
}

.mt-18px {
    margin-top: 18px !important;
}

.mb-18px {
    margin-bottom: 18px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.fs-90em {
    font-size: 0.9em !important;
}

.no-min-height {
    min-height: none;
}

.pr-75rem {
    padding-right: 0.75rem;
}

.pl-75rem {
    padding-left: 0.75rem;
}

.o-highlight-text {
    color: lighten($primary-color, 10%);
}

.o-no-border {
    border: none;
}


/* --------------------------- TOAST  --------------------------- */

.toast {
    width: 500px;
    font-size: 0.9em;
    color: lighten($black-color, 15%) !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $black-color !important;
    }
    p {
        margin-right: 30px;
    }
    .btn {
        background-color: transparent;
        color: $black-color;
        position: absolute;
        top: 5px;
        right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
        border-radius: 0;
    }
}

.toast.success,
.o-toast.success {
    background-color: $success-toast-color;
}

.toast.error,
.o-toast.error {
    background-color: $error-toast-color;
}

.toast.info,
.o-toast.info {
    background-color: $info-toast-color;
}

.toast.warning,
.o-toast.warning {
    color: white !important;
    background-color: $warning-toast-color;
    h6 {
        color: white !important;
    }
}

.o-main {
    display: flex;
    @include media(mdDown) {
        flex-direction: column;
    }
}

.o-row-qr {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
    .o-col-qr {
        margin-left: inherit !important;
        margin: 0 -30px;
    }
    &.o-png {
        margin-top: 40%;
    }
}

.o-container-help-btn {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 999;
}


/* --------------------------- DATE RANGE PICKER  --------------------------- */

.o-date-range-picker.e-popup {
    .e-calendar {
        .e-content {
            .e-end-date.e-selected,
            .e-start-date.e-selected,
            .e-today.e-start-date.e-selected,
            .e-today.e-end-date.e-selected {
                span.e-day {
                    background-color: $primary-color;
                    border: 1px solid $primary-color;
                    color: white;
                    &:hover {
                        background-color: $secondary-color;
                        border: 1px solid $secondary-color;
                        color: white;
                    }
                }
            }
            .e-today,
            .e-today.e-range-hover {
                span.e-day {
                    border: 1px solid $primary-color;
                    color: $primary-color;
                    &:hover {
                        border: 1px solid $secondary-color;
                        color: $secondary-color;
                    }
                }
            }
        }
    }
    .e-footer {
        .e-btn.e-apply {
            color: $primary-color;
        }
        .e-btn.e-apply:hover {
            color: $secondary-color;
        }
        .e-btn.e-cancel {
            color: $black-color;
        }
    }
}