@import "style/scss/GlobalsVariables.scss";
.o-loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    @include media(mdDown) {
        &.o-mobile {
            position: fixed;
            z-index: 990;
            width: 100vw;
            height: 100vh;
            top: 56px;
            left: 0px;
            overflow-y: hidden;
        }
        &.o-full-height {
            top: 0px;
        }
    }
}