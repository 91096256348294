@import "style/scss/GlobalsVariables.scss";
.modal.o-iphone-modal {
    border-radius: $border-radius-input;
    max-height: 80%;
    &.o-full-width {
        width: 90%;
    }
    .modal-content {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
            font-weight: 500;
            margin-bottom: 20px;
            font-size: 1.5em;
            text-align: center;
            padding: 0px 10px;
            //text-transform: lowercase
            .o-image {
                margin: 10px 0px;
            }
            .o-subtitle {
                font-size: 0.9rem;
            }
        }
        .hr {
            width: 99%;
            color: $borders-gray-color;
            margin: 0px;
        }
        .row {
            .col {
                .input-field {
                    padding: 0px;
                }
            }
        }
        .o-inputs-container {
            width: 80%;
        }
        .o-content {
            margin: 10px 20px;
            .row {
                margin-bottom: 0;
                .col {
                    .o-btn-modal {
                        width: 200px;
                        margin: 20px 0;
                        @include media(mdDown) {
                            width: 100%;
                        }
                    }
                }
            }
            span {
                strong {
                    font-weight: bold;
                }
            }
        }
        .o-content-email {
            margin: 10px 20px;
            width: 50%;
        }
        .o-modal-btn {
            &.o-positive {
                color: $primary-color;
            }
            &.o-negative {
                color: red;
            }
            position: relative;
            background-color: transparent;
            text-transform: none;
            font-size: 13px;
            font-weight: 600;
            width: 100%;
            height: 50px;
            margin: 0;
            .o-input-fill {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                margin: 0;
                padding: 0;
                font-size: 20px;
                cursor: pointer;
                opacity: 0;
            }
        }
    }
    .modal-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 100px;
        padding: 0;
        .modal-close {
            text-transform: none;
            font-size: 13px;
            font-weight: 400;
            width: 100%;
            height: 50px;
            margin: 0;
            color: $black-color !important;
        }
    }
}